import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { useMediaQuery } from '../../../../utils/hooks/useMediaQuery';
import { breakpointQueries, breakpoints, colors, spacing } from '../../../../utils/styleguide';
import { colorsKurppa } from '../../../../utils/styleguideKurppa';
import ColorComponent from '../../types/ColorComponent';
import { Col, Row } from '../12ColGridLayout';
import { Arrow, Dots } from '../SliderControls';
import CarouselSlider, { Campaign } from './CarouselSlider';

const DesktopCardWidth = 800;
const MobileCardWidth = 300;

const CampaignListWrapper = styled.div`
  max-width: none;
  overflow: hidden;
  background-color: ${colorsKurppa.grey01};
  padding: ${spacing[10]}px 0 ${spacing[10]}px;
  ${breakpoints.tablet} {
    padding: ${spacing[11]}px 0 ${spacing[10]}px;
  }
`;

const HeaderBlock = styled.div`
  padding: 0 0 ${spacing[6]}px;
  ${breakpoints.tablet} {
    padding: 0 0 ${spacing[8]}px;
  }
`;

const ListNavigation = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type CampaignListLayoutProps = {
  Title: ColorComponent;
  Description?: ColorComponent;
  campaigns: Partial<Campaign>[];
};

const CampaignListLayout = ({ Title, campaigns, Description }: CampaignListLayoutProps) => {
  const [activePostIndex, setactivePostIndex] = useState(0);
  const sliderRef = useRef({} as HTMLDivElement);
  const scrollMeasureTimer = useRef({} as ReturnType<typeof setTimeout>);
  const [isTablet] = useMediaQuery(breakpointQueries.tablet);
  const cardWidth = isTablet ? DesktopCardWidth : MobileCardWidth;
  const cardBorder = isTablet ? 32 : 24;

  const handleCarouselScroll = () => {
    if (scrollMeasureTimer.current) {
      clearTimeout(scrollMeasureTimer.current);
    }
    scrollMeasureTimer.current = setTimeout(() => {
      const index = (sliderRef.current?.scrollLeft || 0) / (cardWidth + cardBorder);
      setactivePostIndex(Math.min(Math.round(index), campaigns.length));
    }, 100);
  };

  const handleClick = (card: 1 | -1) => {
    sliderRef.current.scroll({
      left: (cardWidth + cardBorder) * (activePostIndex + card),
      behavior: 'smooth',
    });
  };

  const previous = () => {
    handleClick(-1);
  };

  const next = () => {
    handleClick(1);
  };

  return (
    <CampaignListWrapper>
      <HeaderBlock>
        <Row>
          <Col
            tablet="span 9"
            css={css`
              margin: 0 0 ${spacing[5]}px;
            `}
          >
            <Title color={colors.black} />
          </Col>
        </Row>
        {!!Description && (
          <Row>
            <Col tablet="span 9">
              <Description color={colors.black} />
            </Col>
          </Row>
        )}
      </HeaderBlock>
      <CarouselSlider
        desktopCardWidth={DesktopCardWidth}
        mobileCardWidth={MobileCardWidth}
        items={campaigns}
        activePostNr={activePostIndex + 1}
        onScroll={handleCarouselScroll}
        ref={sliderRef}
      />
      <Row>
        <ListNavigation>
          <Arrow direction="left" handleClick={previous} />
          <Dots slides={campaigns} activeIndex={activePostIndex} onLight />
          <Arrow direction="right" handleClick={next} />
        </ListNavigation>
      </Row>
    </CampaignListWrapper>
  );
};

export default CampaignListLayout;
