import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { EventHandler, forwardRef, UIEvent } from 'react';
import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import { ImageStyleProps } from '../../../contentful-elements/Image';
import GhostButton from '../../buttons/GhostButton';
import Typography from '../../text/Typography';
import PropsComponent from '../../types/PropsComponent';

type CardContainerProps = {
  desktopCardWidth: number;
  mobileCardWidth: number;
};

const CardContainer = styled.div`
  display: block;
  width: ${(p: CardContainerProps) => p.mobileCardWidth}px;
  margin: 0 ${spacing[2]}px;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
  ${breakpoints.tablet} {
    margin: 0 ${spacing[3]}px;
    width: ${(p: CardContainerProps) => p.desktopCardWidth}px;
  }
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  height: ${508}px;
  background: ${colors.white};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  ${breakpoints.tablet} {
    height: ${404}px;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${spacing[6]}px ${spacing[4]}px ${spacing[4]}px;
  justify-content: space-between;
  height: 100%;
  ${breakpoints.tablet} {
    padding: ${spacing[6]}px;
    justify-content: unset;
    height: unset;
  }
`;

const CampaignText = styled(Typography)`
  overflow-wrap: break-word;
  margin-top: ${spacing[3]}px;
  ${breakpoints.tablet} {
    margin-top: ${spacing[4]}px;
  }
`;

const SubHeader = styled(Typography)`
  overflow-wrap: break-word;
  margin-top: ${spacing[0]}px;
  ${breakpoints.tablet} {
    margin-top: ${spacing[0]}px;
  }
`;

const CampaignLink = styled.div`
  overflow-wrap: break-word;
  margin-top: ${spacing[4]}px;
  ${breakpoints.tablet} {
    margin-top: auto;
  }
`;
const UpperCardContent = styled.div``;

const SnapContainer = styled.div`
  scroll-snap-align: start;
  scroll-margin-left: ${spacing[2]}px;
  ${breakpoints.tablet} {
    scroll-margin-left: ${spacing[3]}px;
  }
  &:last-child {
    min-width: 100vw;
  }
`;

export type Campaign = {
  name: string;
  description: string;
  link: string;
  cta?: string;
  CampaignImage: PropsComponent<ImageStyleProps>;
  Logo: PropsComponent<ImageStyleProps>;
  subheader?: string;
};

type CampaignCardProps = Partial<Campaign> & {
  hideShadow?: boolean;
  desktopCardWidth: number;
  mobileCardWidth: number;
};

const CampaignCard = ({
  name,
  description,
  link,
  cta = 'Read more',
  CampaignImage,
  Logo,
  desktopCardWidth,
  mobileCardWidth,
  subheader,
}: CampaignCardProps) => {
  return (
    <SnapContainer>
      <CardContainer desktopCardWidth={desktopCardWidth} mobileCardWidth={mobileCardWidth}>
        <Card>
          <CardContent>
            <UpperCardContent>
              {Logo ? (
                <Logo
                  wrapperCss={css`
                    height: 24px;
                    object-fit: contain;
                    z-index: 1;
                  `}
                  css={css`
                    object-fit: contain;
                    height: 100%;
                  `}
                />
              ) : null}
              <CampaignText variant="h5">{name}</CampaignText>
              {subheader && <SubHeader variant="bodySmall">{subheader}</SubHeader>}
              <CampaignText variant="bodySmall" color={colors.darkGrey}>
                {description}
              </CampaignText>
            </UpperCardContent>
            {link && (
              <CampaignLink>
                <GhostButton
                  href={link}
                  label={cta}
                  trailingIconName="arrow-long-right"
                  hideUnderline
                  color={colors.info}
                />
              </CampaignLink>
            )}
          </CardContent>
          {CampaignImage ? (
            <CampaignImage
              wrapperCss={css`
                background: ${colors.black};
                width: 100%;
                height: ${170}px;
                ${breakpoints.tablet} {
                  width: 50%;
                  height: 100%;
                }
              `}
              css={css`
                object-fit: cover;
                width: 100%;
                height: 100%;
              `}
            />
          ) : null}
        </Card>
      </CardContainer>
    </SnapContainer>
  );
};

const OuterWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${spacing[1]}px ${spacing[4]}px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.tablet} {
    padding: 0 ${spacing[7]}px ${spacing[6]}px;
  }
`;

interface CarouselSliderProps {
  items: Partial<Campaign>[];
  activePostNr: number;
  onScroll: EventHandler<UIEvent<HTMLElement>>;
  desktopCardWidth: number;
  mobileCardWidth: number;
}

const CarouselSlider = forwardRef<HTMLDivElement, CarouselSliderProps>(function CarouselSlider(
  { items, activePostNr, onScroll, desktopCardWidth, mobileCardWidth },
  ref,
) {
  return (
    <OuterWrapper ref={ref} onScroll={onScroll}>
      {items.map(
        ({ name, description, link, cta, CampaignImage, Logo, subheader }, index: number) => (
          <CampaignCard
            key={index}
            name={name}
            description={description}
            link={link}
            cta={cta}
            CampaignImage={CampaignImage}
            Logo={Logo}
            hideShadow={activePostNr - 1 === index}
            desktopCardWidth={desktopCardWidth}
            mobileCardWidth={mobileCardWidth}
            subheader={subheader}
          />
        ),
      )}
    </OuterWrapper>
  );
});

export default CarouselSlider;
